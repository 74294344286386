.contactHidden{
    @media (max-width: 650px) {
        transition: 1s;
        z-index: -1;
    }
}
.contactField{
    .bdMini{
        background-color: white;
        width: 0px;
        height: 0px;
        border: solid 2px black;
        position: absolute;
        bottom: 34px;
        right: 60px;
        border-radius: 30px;
        border-top-right-radius: 0px 80px;
        transition: 0.25s;
        opacity: 0;
        //transform-origin: top right;
        display: flex;
        margin: auto;
        @media (max-width: 650px) {
            animation: openBd 8s linear infinite ;
        }

    }
    .bdLike{
        background-color: white;
        border: solid 2px black;
        position: absolute;
        right: 60px;
        border-radius: 30px;
        border-top-right-radius: 0px 80px;
        display: flex;
        margin: auto;
        transition: 0.25s;
        width: 160px;
        height: 30px;
        bottom: 4px;
        opacity: 1;
        animation: none;
    }
    @keyframes openBd{
        0% {
            transform: rotateZ(0deg) ;

        }
        89%{
            width: 0px;
            height: 0px;
            bottom: 4px;
            opacity: 0;          
        }
        90%{
            width: 80px;
            height: 30px;
            bottom: 4px;
            opacity: 1;
        }
        100%{
            width: 80px;
            height: 30px;
            bottom: 4px;
            opacity: 1;
        }
    }
    .headerLogos{
        z-index: 11;
        cursor: pointer;


        transform-origin: center;
        position: fixed;
        right: 20px;
        bottom: 20px;
        transition: 1s;
    }
    .maGanache{
        width: 100px;
        //z-index: 10;
        border-radius: 100px;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);

    }
    .headerLogos:hover{
        //bottom: 22px;
        //right: 21px;
        .bdMini{
            @media (min-width: 650px) {
                transition: 0.25s;
                width: 80px;
                height: 30px;
                bottom: 4px;
                opacity: 1;
            }

        }
    }
    .bdMaxi{
        transition: 0.25s;
        width: 35vw;
        right: 0;
        height: 60vh;
        bottom: 4px;
        opacity: 1;
        border-radius: 30px;
        border-bottom-right-radius: 0px 80px;
        background-color: white;
        border: solid 2px black;
        position: absolute;
        //z-index: 11;
        transition: 0.25s;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    .noDiscuss{
        margin: auto;
    }
    .discuss{
        .hidden{
            opacity: 0;
            transition: 1s;
        }
        .gradient{
            position: absolute;
            width: 93%;
            height: 150px;
            top: 2px;
            //margin-left: 5px;
            border-radius: 10px;
            background: linear-gradient(#ffffff, #ffffff00);
            transition: 1s;
        }
        width: 93%;
        height: 100%;
        padding: 10px;
        height: calc(60vh - 100px);
        overflow: scroll;
        margin-top: 10px;
        cursor: default;
        flex-direction: column;

        .txt{
            margin-left: 5px;
            margin-top: -10px;
            //margin-right: 15px;
            @media (max-width: 650px) {
                margin-top: 0;
            }
        }
        .oneMessage{
            //position: relative;
            display: flex;
            flex-direction: row;
            //

            //flex-direction: row;

        }
        .avatar{
            width: 30px;
            height: 30px;
        }
        @keyframes clignote{
            0% {
                opacity: 1;
            }
            50% {
                opacity: 0.5;
            }
            100% {
              opacity: 1 ;
            }  
          }
        .sendButton{
            width: calc(18% - 30px);
            min-width: 30px;
            height: 6vh;
            display: flex;
            cursor: pointer;
            img{
                width: 100%;
                margin: auto;
            }
            background-color: white;
            border-radius: 10px;
            position: absolute;
            bottom: 15px;
            right: 15px;
            animation:  infinite clignote ;
            animation-duration: 2000ms;
            opacity: 1;
            @media (max-width: 650px) {
                height: 8vh
            }
        }

        textarea{
            position: absolute;
            bottom: 10px;
            left: 10px;
            width: calc(85% - 30px);
            height: 6vh;
            resize: none;
            border-bottom-left-radius: 20px 20px;
            transition: 0.1s;
            @media (max-width: 650px) {
                height: 8vh;
            }
        }
        textarea:focus {
            //outline: none !important;
            //outline: red;
            outline:2px solid #99477ab7;
            transition: 0.1s;
            //box-shadow: 0 0 10px #719ECE;
          }
    }
    @media (max-width: 650px) {
        
.bdMaxi{
    width: 90vw;
    height: calc(100dvh - 190px);
}

        
    }
}





