.snowFall {
  /* Start */
}
.snowFall blockquote {
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 255, 0), rgba(157, 59, 143, 0));
}
.snowFall ul, .snowFall li, .snowFall blockquote, .snowFall p {
  margin: 0;
  padding: 0;
  border: none;
  list-style: none;
}
.snowFall body {
  background: #333;
  overflow: hidden;
}
.snowFall .side::before, .snowFall .side::after {
  display: none;
}
.snowFall .side .md > blockquote:last-of-type,
.snowFall .side .md > blockquote:last-of-type p,
.snowFall .side .md > blockquote:last-of-type [href="#snow"] {
  position: absolute;
  top: 0;
  bottom: 0;
  background-size: cover;
  left: 0;
  right: 0;
  pointer-events: none;
}
.snowFall .side .md > blockquote:last-of-type {
  top: 0;
  height: 100%;
  z-index: -1;
}
.snowFall .side .md > blockquote:last-of-type p {
  overflow: hidden;
  left: -100px;
  right: -100px;
  perspective: 100px;
  margin: 0;
}
.snowFall .side .md > blockquote:last-of-type [href="#snow"] {
  display: block;
  height: 200%;
  background-image: url(./snow-sparse.png);
  opacity: 0.6;
  animation: s 10s linear infinite;
  background-size: auto;
}
.snowFall .side .md > blockquote:last-of-type p:nth-of-type(1),
.snowFall .side .md > blockquote:last-of-type p:nth-of-type(2),
.snowFall .side .md > blockquote:last-of-type p:nth-of-type(4),
.snowFall .side .md > blockquote:last-of-type p:nth-of-type(6) {
  animation: sw 5s cubic-bezier(0.445, 0.05, 0.55, 0.95) infinite alternate;
  transform: translateX(-50px);
}
.snowFall .side .md > blockquote:last-of-type p:nth-of-type(1) a,
.snowFall .side .md > blockquote:last-of-type p:nth-of-type(2) a {
  animation-duration: 25s;
}
.snowFall .side .md > blockquote:last-of-type p:nth-of-type(1) {
  animation-duration: 35s;
}
.snowFall .side .md > blockquote:last-of-type p:nth-of-type(1) a {
  transform: translateZ(-25px);
  animation-name: sF;
  width: 4000px;
  top: -500px;
  left: -2000px;
}
.snowFall .side .md > blockquote:last-of-type a:nth-child(2) {
  animation-duration: 10s;
  background-size: 250px;
}
.snowFall .side .md > blockquote:last-of-type a:nth-child(3) {
  animation-duration: 20s;
  background-size: 500px;
}
.snowFall .side .md > blockquote:last-of-type p:nth-of-type(6) a {
  animation-duration: 5s;
  transform: translateZ(75px);
  animation-name: sC;
}
@keyframes s {
  0% {
    transform: translateY(-1000px);
  }
}
@keyframes sC {
  0% {
    transform: translateY(-1000px) translateZ(75px);
  }
}
@keyframes sF {
  0% {
    transform: translateY(-1000px) translateZ(-25px);
  }
}
@keyframes sw {
  to {
    transform: translateX(50px);
  }
}/*# sourceMappingURL=snow.css.map */