.textareafield .windows {
  background-image: url("../../../public/assets/snow.gif");
  border: solid 3px black;
  border-radius: 20px;
  overflow: hidden;
}
.textareafield .windows textarea {
  color: white;
}
.textareafield .windows .like {
  bottom: 10px;
}
@media (max-width: 650px) {
  .textareafield .windows .like {
    bottom: -40px;
  }
}
.textareafield .windows .padding {
  padding-right: 100px;
}
.textareafield .windows .fullResum {
  line-height: 1rem;
  margin: 20px;
  margin-top: 40px;
}
.textareafield .windows .fullResum p {
  font-family: "abacaxi-devanagari", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
}
.textareafield .empty {
  border-radius: 20px;
}
.textareafield .empty .like {
  bottom: 20px;
}
.textareafield .empty .fullResum {
  display: flex;
  flex-direction: column;
  margin: 20px;
  justify-content: space-around;
}
.textareafield .empty .fullResum p {
  font-family: "abacaxi-devanagari", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.textareafield .purpleAndWitheTextarea {
  border-radius: 20px;
  overflow: hidden;
}
.textareafield .purpleAndWitheTextarea .title {
  width: 100%;
  background-color: #99477a;
  color: white;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
}
.textareafield .purpleAndWitheTextarea .mise-en-page {
  background-color: white;
  opacity: 0.8;
  transition: 1s;
}
.textareafield .purpleAndWitheTextarea .mise-en-page:hover {
  opacity: 1;
  transition: 1s;
}
.textareafield .purpleAndWitheTextarea .like {
  bottom: 20px;
}
.textareafield .purpleAndWitheTextarea .fullResum {
  margin: 20px;
}
.textareafield .purpleAndWitheTextarea .fullResum p {
  font-family: "abacaxi-devanagari", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.textareafield .textArea {
  position: relative;
  background-size: 100%;
  overflow: hidden;
}
@media (max-width: 649px) {
  .textareafield .textArea .like {
    right: -35px;
    transition: 1000ms;
  }
}
.textareafield .textArea .sign {
  padding-left: 20px;
  font-family: "abacaxi-devanagari", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  transition: 1s;
  color: #99477a;
  margin-bottom: 4px;
}
.textareafield .textArea .sign:hover {
  transition: 1s;
  text-shadow: 5px 5px 5px;
  padding-left: 16px;
  padding-top: -2px;
}
.textareafield .textArea .softSkills {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.textareafield .textArea .softSkills .logoAndTitle {
  display: flex;
  flex-direction: column;
}
.textareafield .textArea .softSkills .logoAndTitle p {
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.textareafield .textArea .softSkills .logoAndTitle .logo {
  width: 25px;
  margin: auto;
}
.textareafield .textArea .textareaLinks {
  color: white;
  text-align: center;
}
@media (max-width: 649px) {
  .textareafield .textArea .spacedTextArea {
    height: 60px;
  }
}
.textareafield .textArea .like {
  transition: 1000ms;
  position: absolute;
  opacity: 0.6;
  z-index: 6;
  right: -350px;
  border-radius: 20px;
}
@media (max-width: 900px) {
  .textareafield .textArea .like {
    right: 0px;
  }
}
.textareafield .textArea textarea {
  resize: none;
  border: none;
  padding: 20px;
  text-decoration: none;
  background-color: rgba(0, 0, 255, 0);
  transition: all 1s;
  border-radius: 20px;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}
.textareafield .textArea textarea:focus {
  outline: none;
}
.textareafield .textArea:hover .like {
  right: 0px;
  transition: 1000ms;
}
.textareafield .edit:focus {
  outline: none;
  background-image: 0.5;
  background-color: white;
  color: black;
  transition: all 1s;
}/*# sourceMappingURL=textArea.css.map */