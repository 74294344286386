.accueilField{
    
    .contactIndication{
        display: flex;
        width: 100%;
        flex-direction: column;
        p{
            text-align: center;
            font-size: 0.8rem;
            margin-top: 0;
        }
        @media (max-width: 900px){
            margin-top: 60px;
            //min-height: 180vh;
          }
    }
    .contactMe{
        width: 100px;
        //padding-right: 20px;
        margin: auto;
        //margin-top: 30px;
        //align-items: end;
        cursor: pointer;
        z-index: 2;
        color: black;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        //outline: inherit;
        animation: clignote 1s infinite  ;
        img{
            width: 100%;
            margin-left: -35px;
            animation: contact 0.7s ease  ;
        }
    }
    @keyframes contact{
        0% {
            margin-left: -100vw;
        }
        100%{
            margin-left: -35px;
        }
    }
    @keyframes clignote{
        0% {
            opacity: 1;
        }
        50%{
            opacity: 0.3;
        }
        100%{
            opacity: 1;
        }
    }
    //margin-bottom: 0;
    margin-top: 0px;
    //min-height: 90vh;
    margin-bottom: 20px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    @keyframes comming{
        0% {
            margin-top: 70px;

        }

        100%{
            margin-top: 0;
        }}
    .intro{
        position: relative;
        height: 2.5rem;
        overflow: hidden;
        margin-top: 30px;
        //margin-bottom: 30px;
        @media (max-width: 900px){
            margin-bottom: 60px;
            //min-height: 180vh;
          }
        h1{
            position: absolute;
            width: 100%;
            text-align: center;
            margin-top: 0;
            animation: comming 0.5s ease ;
            //padding-top: 10px;
            //overflow: hidden;
            //padding-top: 10px;
         }

        
        /*position: relative; 
        //margin-top: 60px;
        width: 100%;
        height: calc(100vh - 60px);
        //background-color: rgba(0, 0, 0, 0.421);
        div:nth-child(1){
            position: relative;
            height : 0;
            width : 0;
            border-top : calc(100vh - 60px) solid red;
            border-right : 100vw solid transparent;
            //z-index: 4;
            //background-color: rgb(60, 143, 143);
        }
        div:nth-child(2){
            position: absolute;
            top: 0;
            height : 0;
            width : 0;
            border-bottom : calc(100vh - 60px) solid black;
            border-left : 100vw solid transparent;
            //z-index: 4;
            //background-color: rgb(60, 143, 143);
        }*/
    }
    @media (max-width: 900px){

        //min-height: 180vh;
      }
    .videoInReact{
        @media (max-width: 900px){
            flex-direction: column;
          }
        /*p{
            font-family: "abacaxi-devanagari", sans-serif !important;
            font-weight: 500;
        }*/
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //margin-left: 35%;
        position: relative;
        .hardSkills{
            margin-top: 24vh;
            @media (max-width: 900px){
                margin-top: 80px;
              }
            position: relative;
            .oneSkill{
                //padding-right: 40px;
                height: 40px;
                overflow: hidden;
                h3{
                    text-align: center;
                    margin-top: 0;
                    animation: comming 2.5s ease ;
                    //animation-delay: 1s;
                }
            }
            width: 35%;
            min-height: 200px;

            @media (max-width: 900px){
                width: 100%;
                height: 200px;
              }
            .negatif{
                @media (min-width: 900px){
                    display: none;
                  }
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #99477a;
                opacity: 0.5;
                border-radius:30% 70% 70% 30% / 30% 31% 69% 70% ;
                transform: rotate(-12deg);
                //filter: invert(1);
                //border: 1px solid black;
                top: -60px;
                left: 40px;
                z-index: 2;
                animation: commingRight 0.5s ease ;
            }
            @keyframes commingRight{
                0% {
                    left: 130%;
        
                }
        
                100%{
                    left: 40px;
                }}
        }
        .softSkills{
            margin-top: 24vh;
            @media (max-width: 900px){
                margin-top: 80px;
              }
            position: relative;
            .oneSkill{
                //padding-right: 40px;
                height: 40px;
                overflow: hidden;
                h3{
                    text-align: center;
                    margin-top: 0;
                    animation: comming 2.5s ease ;
                    //animation-delay: 1s;
                }
            }
            .negatif{
                @media (min-width: 900px){
                    display: none;
                  }             
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #99477a;
                opacity: 0.5;
                border-radius:42% 58% 70% 30% / 61% 31% 69% 39%  ;
                transform: rotate(20deg);
                //filter: invert(1);
                //border: 1px solid black;
                top: -60px;
                right: 30px;
                z-index: 2;
                animation: commingLeft 0.8s ease ;
            }
            @keyframes commingLeft{
                0% {
                    right: 130%;
        
                }
        
                100%{
                    right: 40px;
                }}
            width: 35%;
            min-height: 200px;
            @media (max-width: 900px){
                width: 100%;
                height: 200px;
              }
        }
        .react{
            width: 30%;
            //margin-left: 10%;
            display: block;
            position: relative;
            aspect-ratio: 1;
            span{
                opacity: 0;
            }
            @media (max-width: 900px){
                display: none;
              }
        }
        /*.video{

            position: absolute;
            top: 30%;
            left: 30%;
            display: flex;
            width: 40%;
            //margin-left: 25%;
            margin: auto;
            margin-bottom: 100px;
            aspect-ratio: 1;
            border-radius: 100%;
            overflow: hidden;
            //margin-top: -10%;
            //z-index: 5;
            video{
                display: none;
                width: 180%;
                position: absolute;
                top: 0;
                left: -50%;
                //height: 200%;
            }
        }*/

    }


    .buttonPlace{
        //float: right;
        //opacity: 0.6;
        width: 100px;
        height: 100px;
        //display: flex;
        //justify-content: center;
        top: calc(50% - 50px);
        left: calc(50% - 50px);
        position: absolute;
        z-index: 4;
        //display: none;
        //width: 10px;
        @media (max-width: 900px){
            //float: bottom;
            padding-top: 30px;
            padding-bottom: 30px;
            width: 30%;
            height: 30%;
            left: 0;
            top: 0;
            margin: auto;
            position: relative;
        }
        @media (min-width: 900px){
            //float: bottom;
            //filter: invert(1);
            .buttonDiv{
                .send
                {
                    .purpleAndWitheTextarea{
                        box-shadow: 0px 0px 10px black ;
                        border: 6px solid black ;
                    }
                    .purpleAndWitheTextarea:hover{
                        width: 80%;
                        margin-left: 4%;
                        margin-top: 4%;
                        box-shadow: 0px 0px 0px black ;
                    }
                }

            }

        }
    }
    p{
        font-family: "hayanbaram", sans-serif;
        font-weight: lighter;
        font-size: 1.2rem;
        line-height: 1;
    }
    h2{
        font-family: "hayanbaram", sans-serif;
        font-weight: lighter;
        font-size: 1.6rem;
        margin-bottom: 30px;
        margin-top: 100px;
        @media (max-width: 900px){
            margin-top: 0;
        }
    }  
}