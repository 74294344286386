.error404{
    height: calc(100dvh - 60px);
    @media (max-width: 650px){
        height: calc(100dvh - 150px);
      }
    position: relative;
    overflow: hidden;

    .errorRedirect{
        text-align: center;
        padding-top: 30dvh;
    }
    .travoltaContainer{
        width: 100%;
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 0;
        z-index: 5;
        .travolta{
            height: calc(60dvh - 60px);
            @media (max-width: 650px){
                height: calc(60dvh - 150px);
            }
        }
    }
}

