.cardField {
  width: 20%;
  min-width: 250px;
  max-width: 350px;
  height: 350px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  margin: 20px;
  z-index: 5;
  border: solid white;
  text-align: center;
}
.cardField .logoCards {
  position: absolute;
  z-index: 6;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
}
.cardField .logoCards .topProj {
  height: 33px;
  width: 35px;
}
.cardField .logoCards .studies {
  width: 35px;
}
.cardField p {
  font-size: 1rem;
}
.cardField .more {
  position: absolute;
  bottom: -10px;
  right: 10px;
  font-size: 10px;
  opacity: 0.5;
}
@media (max-width: 650px) {
  .cardField {
    max-width: 300px;
  }
}
@media (max-width: 900px) {
  .cardField {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.cardField .likeSlide {
  position: relative;
  top: 114px;
}
.cardField .cardSlide {
  opacity: 1;
  height: 162px;
}
.cardField .cardResum {
  position: absolute;
  top: 150px;
  color: white;
  background-color: #99477a;
  opacity: 0.8;
  transition: all ease-in-out 4s;
  z-index: 1;
  height: calc(100% - 150px);
  min-width: 230px;
  width: 100%;
  display: flex;
}
.cardField .cardResum .texte {
  padding: 10px;
  margin: auto;
}
.cardField .cardResum .likeCard {
  position: relative;
  top: 150px;
}

.cardField:hover .cardResum {
  opacity: 1;
  transition: all ease-in-out 4s;
}
.cardField:hover .likeContainer {
  right: -20px;
  transition: 1000ms;
}
.cardField:hover .more {
  opacity: 1;
}/*# sourceMappingURL=cards.css.map */