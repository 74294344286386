.home {
    .fadeIn{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: white;
        opacity: 0;
        animation-name: fade;
        animation-duration: 1000ms;
    }
    @keyframes fade{
        0% {
            opacity: 1;
        }
        
        100% {
            opacity: 0;
        }
    }
    .without{
        background-color: white;
    }
    position: relative;
    overflow: hidden;
    .background{
        position: absolute;
        top: 0;
        z-index: -1;
    }
    .pictureJap{
        img{
            z-index: 2;
        }
    }
    .contact{
        width: 100%;
        height: 170dvh;
        position: absolute;
        background: linear-gradient(rgb(255, 255, 255), rgba(157, 59, 143, 0.0));
    }
    .reco:hover {

            .likeContainer{
                right: -20px;
                transition: 1000ms;
            }
        
    }
    .recoAnchor{
        position: absolute;
        top: -20dvh;
    }
    #competences{
        position: absolute;
        top: -20dvh; 
        @media (max-width: 900px) {
            top: -30dvh;
        }
    }
    .reco{
        margin-top: -20px;
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100dvw;
        overflow: hidden;
        background-color: white;
        @media (min-width: 900px) {
            height: 98vh;
        }
        @media (max-width: 900px) {
            padding-top: 30px;
        }
        @media (max-width: 650px) {
            flex-direction: column-reverse;
            .recoTxt{
                width: 100%;
            }
            .buttonAndBackground{
                margin-bottom: 20px;
            }
          }
        .recoTxt{
            width: 90%;
            margin: auto;
        }
        .likeContainer{
            bottom: 25px;
            opacity: 0.6;
        }
    }
    .tools{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        @media (max-width: 650px){
            flex-direction: column;
        }
        margin-top: 80px;
        height: fit-content;
    }
    .collapse{
        width: 30%;
        padding-left: 1dvw;
        position: relative;
        z-index: 4;
        @media (max-width: 650px){
            width: 90%;
            margin: auto;
        }
        .collapseContent:hover{
            @media (min-width:650px) {
                .likeContainer{
                    right: -20px;
                    transition: 1000ms;
                }
            }
        }
        .collapseContent{
            @media  (max-width: 649px){
                .likeContainer{
                    //right: -10px;
                    transition: 1000ms;
                }
            }
            .toolTitle{
                margin: auto;
            }
        }
    }
    .competences{
        width: 100dvw;
        margin-top: 100px;
        .contentSkills{
            width: 90%;
            margin: auto;
            position: relative;
            z-index: 4;
            .fullResum{
                min-height: 25vh;
            }
        }
        @media (max-width: 650px){
            width: 100%;
        }
    }
    .skills{
        margin: auto;
    }
    .logoAndTitle{
        display: flex;
        flex-direction: row;
        .logo{
            align-items: center;
        }
    }
    .collapseContent{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .accueil{
        position: relative;
        margin-bottom: 0px;
    }
    #accueil{
        position: absolute;
        top: -60px;
        @media (max-width: 650px){
            top: -130px;
        }
    }
    .competences{
        position: relative;
    }
    .projets{
        margin-top: 50px;
        padding-top: 150px;
        margin-bottom: 150px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        position: relative;
        @media (max-width: 900px){
            margin-top: 50px;
            padding-top: 150px;
        }
        @media (max-width: 650px){
            flex-direction: column;
            flex-wrap: nowrap;
            padding-top: 200px;
            //justify-content: center;
            //padding-top: 250px;
        }
        .projectChoice{
            left: 50%;
            transform: translate(-50%,-50%);
            //max-width: 90%;
            position: absolute;
            z-index: 6;
            top: 100px;
            background-color: rgba(255, 255, 255, 0.747);
            //border: 2px solid black;
            border-radius: 30px;
            padding-left: 15px;
            padding-right: 15px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            @media (max-width: 650px){
                width: 80%;
            }
            .choice{
                cursor: pointer;
                
            }
            input[type="radio"] {
                -webkit-appearance: none;
                appearance: none;
                margin: auto;
                font: inherit;
                color: black;
                width: fit-content;
                height: fit-content;
                padding: 4px;
                margin: 3px;
                border-radius: 20px;
                place-content: center;
                transition: all 1s;
                margin-top: 8px;
                margin-bottom: 8px;
              }
              input[type="radio"]::before {
                padding: 6px;
                margin: 3px;


                border-radius: 20px;
                transition: all 1s;
              }
              input[type="radio"]:checked::before {
                padding: 6px;
                padding-left: 12px;
                padding-right: 12px;

                margin: 3px;
                border-radius: 20px;
                background-color: #99477a;
                color: white;
                transition: all 1s;
              }
              input[type="radio"]:focus {

              }
              
              .Tousfr::before{
                content: "Tous";
              }
              .Touseng::before{
                content: "All";
              }
              .cms::before{
                content: "C.M.S";
              }
              .frontEnd::before{
                content: "Front-end";
              }
              .fullStack::before{
                content: "Full-stack";
              }
              .topProjectfr::before{
                content: "Top projets";
              }
              .topProjecteng::before{
                content: "Top projects";
              }
              .design::before{
                content: "Design";
              }
              
        }
    }
}

