.signPage{
    .fadeIn{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: white;
        opacity: 0;
        animation-name: fade;
        animation-duration: 1000ms;

    }
    @keyframes fade{
        0% {
            opacity: 1;
        }
        
        100% {
            opacity: 0;
            //z-index: -5;
        }
    }
    display: flex;
    flex-direction: row;
    background: linear-gradient(rgb(255, 255, 255), rgba(255, 255, 255, 0.6));
    @media (max-width: 650px){
        flex-direction: column-reverse;;
    }
    img{
        width: 30%;
        margin: auto;
        margin-top: 100px;
        @media (max-width: 650px){
            margin-top: 20px;
            width: 60%;
        }
    }
    .signin{
        display: flex;
        flex-direction: column;
        margin-left: 25dvw;
        margin-top: 12dvh;
        width: 25%;
        z-index: 1;
        @media (max-width: 650px){
            margin: 2dvw;
            width: 96dvw;
            p{
                font-size: 1rem;
            }
        }
        form{
            @media (max-width: 650px){
                margin: auto;
            }
        }
        .backOfficeTxt{
            min-height: 200px;
            @media (max-width: 650px){
                min-height: 140px;
            }
        }
        .remember{
            margin-left: 10px;
        }
        input:-webkit-autofill {
            //-webkit-text-fill-color: green;
            transition: all 5000s ease-in-out 0s;
        }
        #username{
            background: transparent;
            background-color: transparent;
            color: black;
            border: 2px solid black;
            border-radius: 5px;
        }
        #password{
            background: transparent;
            background-color: transparent;
            color: black;
            border: 2px solid black;
            border-radius: 5px; 
        }
        input[type=checkbox] {
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
        }
        input[type=checkbox] {
            border-radius: 5px;
            height: 15px;
            width: 15px;
            background: #fff;
            border: 2px solid black;
            overflow: hidden;
            margin: auto;
            margin-top: 5px;
            margin-left: 100px;
            transition: 0.5s;
        }
        .connectMe{
            background-color: white;
            border-radius: 5px;
            border:2px solid black;
            margin-left: 100px;
            color: white;
            height: 35px;
            transition: 1s;
            img{
                margin: auto;
                margin-top: 2px;
                width: 80px;
                filter: invert(1);
            }
        }
        .connectMe:hover{
            cursor: pointer;
            box-shadow: 0px 4px 6px black;
            transition: 2s;
        }
        input[type="checkbox"]:hover {
            cursor: pointer;
        }
        input[type="checkbox"]:checked {
            position: relative;
            margin: auto;
            margin-top: 5px;
            margin-left: 100px;
            transition: all 1s;
            background-color: black;
            &:before {
                background-color: black;
                content: '';
                display: block;
                position: absolute;
                height: 15px;
                width: 15px;
            }
        }
        .username{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            label{
                width: 100px;
            }
        }
        .password{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 5px;
            label{
                width: 100px;
            }
        }
    }
}