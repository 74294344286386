.flyers {
  margin-top: 10px;
  width: 100%;
  position: relative;
}
.flyers .my-masonry-grid {
  display: flex;
  flex-direction: row;
}
.flyers .flyer {
  display: flex;
  width: 96%;
  margin: auto;
  margin-bottom: 4%;
  border-radius: 5px;
  cursor: pointer;
}
.flyers .modalContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 4;
  margin-top: -10px;
  margin-left: 0;
}
.flyers .flyContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flyers .flyModal {
  margin-top: 10px;
  width: 97%;
  margin-left: 1%;
  height: 86%;
  z-index: 2;
  position: fixed;
  background-color: white;
  border: solid 2px black;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (max-width: 900px) {
  .flyers .flyModal {
    height: 70%;
  }
}
.flyers .flyModal .cross {
  width: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  border-radius: 20px;
  background-color: white;
}
.flyers .flyModal .fullPic {
  -o-object-fit: contain;
     object-fit: contain;
  max-width: 98%;
  max-height: 98%;
  margin-left: 1%;
}
.flyers .hiddenModal {
  display: none;
}/*# sourceMappingURL=Flyers.css.map */