.sliderContainer {
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    position: relative;
    overflow: hidden;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    .likeSlider{
      position: absolute;
      z-index: 5;
      bottom: 1dvh;
      right: 0px;
      filter: invert(1);
      background-color: white;
      border-radius: 20px 0 0 20px;
      padding-left: 10px;
      opacity: 0.8;
    } 
    .carousel-item {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      overflow: hidden;
    }
    .sl{
      transition: translate 1.2s;
      position: absolute;
      object-fit: cover;
      height: 100%;
      top: 0;
      display: flex;
      justify-content: center;
    }
    .slMax{
      min-width: 100dvw;
    }
    .carousel-img {
      object-fit: cover;
      position: relative;
      @media ( aspect-ratio> 0.935 / 1) {
          width: 100%;  
      }
      @media ( aspect-ratio< 0.935 / 1) {
          height: 100%;
      }
    }
    .carousel-img-mini {
      object-fit: cover;
      position: relative;
      width: 100%;
      max-width: 100%;
    }
    .sliderContent{
      color: white;
      text-align: center;
      font-size: 1.5rem;
      white-space: initial;
      background-color: black;
      margin: 20px;
      padding: 10px;
      border-radius: 20px;
      opacity: 0.8;
      border: solid 2px white;
      @media (max-width: 650px) {
          font-size: 1rem;
      }
    }
    .sliderTextDiv{
      bottom: 10dvh;
      z-index: 4;
      position:absolute;
      width: 50dvw;
    }
    .arrowAndCounter{
      display: flex;
      justify-content: center;
    }
    .counter {
      position: absolute;
      z-index: 3;
      bottom: 2dvh;
      font-size: 18px;
      color: #FFF; 
      padding: 10px;
      border-radius: 20px;
      display: flex;
      flex-direction: row;
    }
    .arrowLeft {
      position: absolute;
      z-index: 7;
      top: 30dvh;
      left: 0px;
      cursor: pointer;
      opacity: 1;
      transition: 1s;
    }
    .arrowRight {
      position: absolute;
      z-index: 7;
      top: 30dvh;
      right: 0px;
      cursor: pointer;
      opacity: 1;
      transition: 1s;
    }
    .cooldown{
      opacity: 0;
      cursor: default;
      transition: 1s;
    }
    .previous{
      translate: -100%;
    }
    .selected{
      translate: 0%;
    }
    .next{
      translate: 100%;
    }
    .start{
      translate: 100%;
    }
    .beforStart{
      translate: -100%;
    }
    .dot{
      background-color: white;
      width: 12px;
      height: 12px;
      border-radius: 15px;
      margin: 10px;
      z-index: 5;
      border: solid 3px black;
      transition: 0.4s;
    }
    .dotSelected{
      margin-top: 5px;
      width: 20px;
      height: 20px;
      transition: 0.4s;
    }  
    .inner {
      display: flex;
      flex-direction: row;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      justify-content: center;
    }
    .buttonArrow{
      border: 0px;
      background: none;
    } 
    .blinded{
      opacity: 0;
    }
}
.sliderField:hover {
    .likeContainer{
      right: -20px;
      transition: 1000ms;
      bottom: 30px;
    }
}
.sliderField{
    .likeContainer{
      bottom: 30px;
    }
}
