.loader {
  display: flex;
  margin: auto;
  margin-top: 200px;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.loader .fadeOut {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  transition: all 1s;
  z-index: 2;
}
.loader .faded {
  opacity: 1;
  transition: all 1s;
}
@keyframes loader {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fondloader {
  0% {
    opacity: 1;
    pointer-events: visible;
  }
  50% {
    opacity: 1;
    pointer-events: visible;
  }
  99% {
    opacity: 0;
    pointer-events: visible;
  }
  100% {
    pointer-events: none;
  }
}
.loader .loading {
  width: 100%;
  display: flex;
  margin: auto;
  margin-top: 0;
  justify-content: center;
}
.loader .content__loader {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  opacity: 1;
  pointer-events: none;
}
.loader .content__loader .loaderPlace {
  position: absolute;
  left: -3px;
  top: 50px;
}
.loader .content__loader .loader__1 {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: black;
  position: absolute;
  opacity: 0;
  left: 9px;
  top: 3px;
  animation: infinite;
  animation-name: loader;
  animation-duration: 1000ms;
  animation-delay: 0ms;
}
.loader .content__loader .loader__2 {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: black;
  opacity: 0;
  position: absolute;
  left: 12px;
  top: 12px;
  animation: infinite;
  animation-name: loader;
  animation-duration: 1000ms;
  animation-delay: 125ms;
}
.loader .content__loader .loader__3 {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: black;
  opacity: 0;
  position: absolute;
  left: 9px;
  top: 21px;
  animation: infinite;
  animation-name: loader;
  animation-duration: 1000ms;
  animation-delay: 250ms;
}
.loader .content__loader .loader__4 {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: black;
  opacity: 0;
  position: absolute;
  top: 24px;
  animation: infinite;
  animation-name: loader;
  animation-duration: 1000ms;
  animation-delay: 375ms;
}
.loader .content__loader .loader__5 {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: black;
  opacity: 0;
  position: absolute;
  left: -9px;
  top: 21px;
  animation: infinite;
  animation-name: loader;
  animation-duration: 1000ms;
  animation-delay: 500ms;
}
.loader .content__loader .loader__6 {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: black;
  opacity: 0;
  position: absolute;
  left: -12px;
  top: 12px;
  animation: infinite;
  animation-name: loader;
  animation-duration: 1000ms;
  animation-delay: 625ms;
}
.loader .content__loader .loader__7 {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: black;
  opacity: 0;
  position: absolute;
  left: -9px;
  top: 3px;
  animation: infinite;
  animation-name: loader;
  animation-duration: 1000ms;
  animation-delay: 750ms;
}
.loader .content__loader .loader__8 {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: black;
  opacity: 0;
  position: absolute;
  animation: infinite;
  animation-name: loader;
  animation-duration: 1000ms;
  animation-delay: 875ms;
}/*# sourceMappingURL=loader.css.map */