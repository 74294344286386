.home {
  position: relative;
  overflow: hidden;
}
.home .fadeIn {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  animation-name: fade;
  animation-duration: 1000ms;
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.home .without {
  background-color: white;
}
.home .background {
  position: absolute;
  top: 0;
  z-index: -1;
}
.home .pictureJap img {
  z-index: 2;
}
.home .contact {
  width: 100%;
  height: 170dvh;
  position: absolute;
  background: linear-gradient(rgb(255, 255, 255), rgba(157, 59, 143, 0));
}
.home .reco:hover .likeContainer {
  right: -20px;
  transition: 1000ms;
}
.home .recoAnchor {
  position: absolute;
  top: -20dvh;
}
.home #competences {
  position: absolute;
  top: -20dvh;
}
@media (max-width: 900px) {
  .home #competences {
    top: -30dvh;
  }
}
.home .reco {
  margin-top: -20px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100dvw;
  overflow: hidden;
  background-color: white;
}
@media (min-width: 900px) {
  .home .reco {
    height: 98vh;
  }
}
@media (max-width: 900px) {
  .home .reco {
    padding-top: 30px;
  }
}
@media (max-width: 650px) {
  .home .reco {
    flex-direction: column-reverse;
  }
  .home .reco .recoTxt {
    width: 100%;
  }
  .home .reco .buttonAndBackground {
    margin-bottom: 20px;
  }
}
.home .reco .recoTxt {
  width: 90%;
  margin: auto;
}
.home .reco .likeContainer {
  bottom: 25px;
  opacity: 0.6;
}
.home .tools {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-top: 80px;
  height: -moz-fit-content;
  height: fit-content;
}
@media (max-width: 650px) {
  .home .tools {
    flex-direction: column;
  }
}
.home .collapse {
  width: 30%;
  padding-left: 1dvw;
  position: relative;
  z-index: 4;
}
@media (max-width: 650px) {
  .home .collapse {
    width: 90%;
    margin: auto;
  }
}
@media (min-width: 650px) {
  .home .collapse .collapseContent:hover .likeContainer {
    right: -20px;
    transition: 1000ms;
  }
}
@media (max-width: 649px) {
  .home .collapse .collapseContent .likeContainer {
    transition: 1000ms;
  }
}
.home .collapse .collapseContent .toolTitle {
  margin: auto;
}
.home .competences {
  width: 100dvw;
  margin-top: 100px;
}
.home .competences .contentSkills {
  width: 90%;
  margin: auto;
  position: relative;
  z-index: 4;
}
.home .competences .contentSkills .fullResum {
  min-height: 25vh;
}
@media (max-width: 650px) {
  .home .competences {
    width: 100%;
  }
}
.home .skills {
  margin: auto;
}
.home .logoAndTitle {
  display: flex;
  flex-direction: row;
}
.home .logoAndTitle .logo {
  align-items: center;
}
.home .collapseContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.home .accueil {
  position: relative;
  margin-bottom: 0px;
}
.home #accueil {
  position: absolute;
  top: -60px;
}
@media (max-width: 650px) {
  .home #accueil {
    top: -130px;
  }
}
.home .competences {
  position: relative;
}
.home .projets {
  margin-top: 50px;
  padding-top: 150px;
  margin-bottom: 150px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
}
@media (max-width: 900px) {
  .home .projets {
    margin-top: 50px;
    padding-top: 150px;
  }
}
@media (max-width: 650px) {
  .home .projets {
    flex-direction: column;
    flex-wrap: nowrap;
    padding-top: 200px;
  }
}
.home .projets .projectChoice {
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 6;
  top: 100px;
  background-color: rgba(255, 255, 255, 0.747);
  border-radius: 30px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
@media (max-width: 650px) {
  .home .projets .projectChoice {
    width: 80%;
  }
}
.home .projets .projectChoice .choice {
  cursor: pointer;
}
.home .projets .projectChoice input[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  margin: auto;
  font: inherit;
  color: black;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 4px;
  margin: 3px;
  border-radius: 20px;
  place-content: center;
  transition: all 1s;
  margin-top: 8px;
  margin-bottom: 8px;
}
.home .projets .projectChoice input[type=radio]::before {
  padding: 6px;
  margin: 3px;
  border-radius: 20px;
  transition: all 1s;
}
.home .projets .projectChoice input[type=radio]:checked::before {
  padding: 6px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 3px;
  border-radius: 20px;
  background-color: #99477a;
  color: white;
  transition: all 1s;
}
.home .projets .projectChoice .Tousfr::before {
  content: "Tous";
}
.home .projets .projectChoice .Touseng::before {
  content: "All";
}
.home .projets .projectChoice .cms::before {
  content: "C.M.S";
}
.home .projets .projectChoice .frontEnd::before {
  content: "Front-end";
}
.home .projets .projectChoice .fullStack::before {
  content: "Full-stack";
}
.home .projets .projectChoice .topProjectfr::before {
  content: "Top projets";
}
.home .projets .projectChoice .topProjecteng::before {
  content: "Top projects";
}
.home .projets .projectChoice .design::before {
  content: "Design";
}/*# sourceMappingURL=home.css.map */