.flyers{
    margin-top: 10px;
    width: 100%;
    position: relative;
    .my-masonry-grid{
        display: flex;
        flex-direction: row;
    }
    .flyer{
        display: flex;
        width: 96%;
        margin: auto;
        margin-bottom: 4%;
        border-radius: 5px;
        cursor: pointer;
    }
    .modalContainer{
        position: fixed;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 4;
        margin-top: -10px;
        margin-left: 0;
    }
    .flyContainer{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .flyModal{
        margin-top: 10px;
        width: 97%;
        margin-left: 1%;
        //height: 86dvh;
        height: 86%;
            @media (max-width: 900px) {
            height: 70%;
            }
        z-index: 2;
        position: fixed;
        background-color: white;
        border: solid 2px black;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        object-fit: contain;
        .cross{
            width: 25px;
            position: absolute;
            right: 10px;
            top: 10px;
            cursor: pointer;
            border-radius: 20px;
            background-color: white;
        }
        .fullPic{
            object-fit: contain;
            max-width: 98%;
            max-height: 98%;
            margin-left: 1%;
        }
    }
    .hiddenModal{
        display: none;
    }
}