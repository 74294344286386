.mainDashboard{
    .dashboard{
        margin-top: 12dvh;
        text-align: center;
    }
    h3{
        text-align: center;
    }
    fieldset{
        border-radius: 10px;
        width: 95%;
        margin: auto;
    }
}

