/*body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}*/

/** {
    outline: 1px solid red;
}*/
*{
  overflow-anchor: none ;

}
body{
  overflow-y: scroll;
  margin: 0;
  //background-color: #99477a;;
  background-color: white;
  overflow-anchor: none
}
.body {

  height: 100%;
  width: 100%;
  position: fixed;
  -webkit-overflow-scrolling: touch;

  
}
/**:not(.maGanache):focus{
  outline:2px solid #99477a;
}*/
a{
  text-decoration: none;
}
h1 h2 h3 h4 h5 {
  font-family: "hayanbaram", sans-serif;
  font-weight: 400;
  font-style: normal;
}
h1 {
  font-size: 2.2rem;
  //font-size: 1dvh;
}
h2 {
  font-size: 1.3rem;
  //font-size: 2dvh;
}
p{
  font-family: "abacaxi-devanagari", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2dvw;
  //font-size: 1rem;
  @media (max-width: 650px){
    font-size: 0.7rem;
    //font-size: 2.5dvw;
  }
}
span{
  font-family: "hayanbaram", sans-serif;
  font-weight: 100;
  font-style: normal;
}

textarea , button{
  font-family: "abacaxi-devanagari", sans-serif;
  font-style: normal;
  font-size: 1rem;
  //font-family: "aw-conqueror-didot", serif;
  font-weight: 400;
  font-style: normal;
}

