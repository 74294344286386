
 .buttonDiv{
    position: relative;
    .purpleAndWitheTextarea:hover{
        max-width: 105%;
        height: 105%;
        transition: 1s;
        box-shadow: 0px 10px 10px black;
    }
    .purpleAndWitheTextarea{
        position: relative;
        display: flex;
        border: 6px solid #99477a;
        width: calc(100% - 12px);
        //height: 100%;
        border-radius: 100%;
        transition: 1s;
        background-color: white;
        aspect-ratio: 1;
        img{
            padding-top: 4px;
            width: 30px;
        }
        button{
            margin: auto;
            display: flex;
            position: relative;
        }
        span{
            margin: auto;
            position: relative;
            font-size: 12px;
        }
        .button{
            font-family: "hayanbaram", sans-serif;
            font-weight: 100;
            font-style: normal;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 255, 0.0);
            z-index: 2;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            border: none;
            display: flex;
            flex-direction: column;
        }
        .buttonAndBackground{
            margin: auto;
        }
        .visible{
            cursor: pointer;
        }
    }
    .windows{
        width: 300px;
        height: 35px;
        margin: auto;
        .button{
            color: white;
            transition: all 1s;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 255, 0.0);
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            border: none;
        }
        .buttonAndBackground{
            background-image:   url("../../../public/assets/snow.gif");
            border-radius: 20px;
            overflow: hidden;
            height: 35px;
            width: 300px;
            border: solid black ;
            margin: auto;
        }
        .button:active{
            background-color: white;
            color: black;
            transition: all 0s;
        }
        .visible{
            cursor: pointer;
        }
    }
    .send{
        opacity: 1;
        cursor: pointer;
    }
    .cantSend{
        opacity: 0.6;
            .button:active{
                background-color: transparent;
                color: white;
                transition: all 0s;
            }
        
    }   
}
