.accueilField {
  margin-top: 0px;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.accueilField .contactIndication {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.accueilField .contactIndication p {
  text-align: center;
  font-size: 0.8rem;
  margin-top: 0;
}
@media (max-width: 900px) {
  .accueilField .contactIndication {
    margin-top: 60px;
  }
}
.accueilField .contactMe {
  width: 100px;
  margin: auto;
  cursor: pointer;
  z-index: 2;
  color: black;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  animation: clignote 1s infinite;
}
.accueilField .contactMe img {
  width: 100%;
  margin-left: -35px;
  animation: contact 0.7s ease;
}
@keyframes contact {
  0% {
    margin-left: -100vw;
  }
  100% {
    margin-left: -35px;
  }
}
@keyframes clignote {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@keyframes comming {
  0% {
    margin-top: 70px;
  }
  100% {
    margin-top: 0;
  }
}
.accueilField .intro {
  position: relative;
  height: 2.5rem;
  overflow: hidden;
  margin-top: 30px;
  /*position: relative; 
  //margin-top: 60px;
  width: 100%;
  height: calc(100vh - 60px);
  //background-color: rgba(0, 0, 0, 0.421);
  div:nth-child(1){
      position: relative;
      height : 0;
      width : 0;
      border-top : calc(100vh - 60px) solid red;
      border-right : 100vw solid transparent;
      //z-index: 4;
      //background-color: rgb(60, 143, 143);
  }
  div:nth-child(2){
      position: absolute;
      top: 0;
      height : 0;
      width : 0;
      border-bottom : calc(100vh - 60px) solid black;
      border-left : 100vw solid transparent;
      //z-index: 4;
      //background-color: rgb(60, 143, 143);
  }*/
}
@media (max-width: 900px) {
  .accueilField .intro {
    margin-bottom: 60px;
  }
}
.accueilField .intro h1 {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: 0;
  animation: comming 0.5s ease;
}
.accueilField .videoInReact {
  /*p{
      font-family: "abacaxi-devanagari", sans-serif !important;
      font-weight: 500;
  }*/
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  /*.video{

      position: absolute;
      top: 30%;
      left: 30%;
      display: flex;
      width: 40%;
      //margin-left: 25%;
      margin: auto;
      margin-bottom: 100px;
      aspect-ratio: 1;
      border-radius: 100%;
      overflow: hidden;
      //margin-top: -10%;
      //z-index: 5;
      video{
          display: none;
          width: 180%;
          position: absolute;
          top: 0;
          left: -50%;
          //height: 200%;
      }
  }*/
}
@media (max-width: 900px) {
  .accueilField .videoInReact {
    flex-direction: column;
  }
}
.accueilField .videoInReact .hardSkills {
  margin-top: 24vh;
  position: relative;
  width: 35%;
  min-height: 200px;
}
@media (max-width: 900px) {
  .accueilField .videoInReact .hardSkills {
    margin-top: 80px;
  }
}
.accueilField .videoInReact .hardSkills .oneSkill {
  height: 40px;
  overflow: hidden;
}
.accueilField .videoInReact .hardSkills .oneSkill h3 {
  text-align: center;
  margin-top: 0;
  animation: comming 2.5s ease;
}
@media (max-width: 900px) {
  .accueilField .videoInReact .hardSkills {
    width: 100%;
    height: 200px;
  }
}
.accueilField .videoInReact .hardSkills .negatif {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #99477a;
  opacity: 0.5;
  border-radius: 30% 70% 70% 30%/30% 31% 69% 70%;
  transform: rotate(-12deg);
  top: -60px;
  left: 40px;
  z-index: 2;
  animation: commingRight 0.5s ease;
}
@media (min-width: 900px) {
  .accueilField .videoInReact .hardSkills .negatif {
    display: none;
  }
}
@keyframes commingRight {
  0% {
    left: 130%;
  }
  100% {
    left: 40px;
  }
}
.accueilField .videoInReact .softSkills {
  margin-top: 24vh;
  position: relative;
  width: 35%;
  min-height: 200px;
}
@media (max-width: 900px) {
  .accueilField .videoInReact .softSkills {
    margin-top: 80px;
  }
}
.accueilField .videoInReact .softSkills .oneSkill {
  height: 40px;
  overflow: hidden;
}
.accueilField .videoInReact .softSkills .oneSkill h3 {
  text-align: center;
  margin-top: 0;
  animation: comming 2.5s ease;
}
.accueilField .videoInReact .softSkills .negatif {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #99477a;
  opacity: 0.5;
  border-radius: 42% 58% 70% 30%/61% 31% 69% 39%;
  transform: rotate(20deg);
  top: -60px;
  right: 30px;
  z-index: 2;
  animation: commingLeft 0.8s ease;
}
@media (min-width: 900px) {
  .accueilField .videoInReact .softSkills .negatif {
    display: none;
  }
}
@keyframes commingLeft {
  0% {
    right: 130%;
  }
  100% {
    right: 40px;
  }
}
@media (max-width: 900px) {
  .accueilField .videoInReact .softSkills {
    width: 100%;
    height: 200px;
  }
}
.accueilField .videoInReact .react {
  width: 30%;
  display: block;
  position: relative;
  aspect-ratio: 1;
}
.accueilField .videoInReact .react span {
  opacity: 0;
}
@media (max-width: 900px) {
  .accueilField .videoInReact .react {
    display: none;
  }
}
.accueilField .buttonPlace {
  width: 100px;
  height: 100px;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  position: absolute;
  z-index: 4;
}
@media (max-width: 900px) {
  .accueilField .buttonPlace {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 30%;
    height: 30%;
    left: 0;
    top: 0;
    margin: auto;
    position: relative;
  }
}
@media (min-width: 900px) {
  .accueilField .buttonPlace .buttonDiv .send .purpleAndWitheTextarea {
    box-shadow: 0px 0px 10px black;
    border: 6px solid black;
  }
  .accueilField .buttonPlace .buttonDiv .send .purpleAndWitheTextarea:hover {
    width: 80%;
    margin-left: 4%;
    margin-top: 4%;
    box-shadow: 0px 0px 0px black;
  }
}
.accueilField p {
  font-family: "hayanbaram", sans-serif;
  font-weight: lighter;
  font-size: 1.2rem;
  line-height: 1;
}
.accueilField h2 {
  font-family: "hayanbaram", sans-serif;
  font-weight: lighter;
  font-size: 1.6rem;
  margin-bottom: 30px;
  margin-top: 100px;
}
@media (max-width: 900px) {
  .accueilField h2 {
    margin-top: 0;
  }
}/*# sourceMappingURL=accueil.css.map */