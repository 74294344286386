@media (max-width: 650px) {
  .contactHidden {
    transition: 1s;
    z-index: -1;
  }
}

.contactField {
  position: relative;
  display: flex;
  flex-direction: column;
}
.contactField .bdMini {
  background-color: white;
  width: 0px;
  height: 0px;
  border: solid 2px black;
  position: absolute;
  bottom: 34px;
  right: 60px;
  border-radius: 30px;
  border-top-right-radius: 0px 80px;
  transition: 0.25s;
  opacity: 0;
  display: flex;
  margin: auto;
}
@media (max-width: 650px) {
  .contactField .bdMini {
    animation: openBd 8s linear infinite;
  }
}
.contactField .bdLike {
  background-color: white;
  border: solid 2px black;
  position: absolute;
  right: 60px;
  border-radius: 30px;
  border-top-right-radius: 0px 80px;
  display: flex;
  margin: auto;
  transition: 0.25s;
  width: 160px;
  height: 30px;
  bottom: 4px;
  opacity: 1;
  animation: none;
}
@keyframes openBd {
  0% {
    transform: rotateZ(0deg);
  }
  89% {
    width: 0px;
    height: 0px;
    bottom: 4px;
    opacity: 0;
  }
  90% {
    width: 80px;
    height: 30px;
    bottom: 4px;
    opacity: 1;
  }
  100% {
    width: 80px;
    height: 30px;
    bottom: 4px;
    opacity: 1;
  }
}
.contactField .headerLogos {
  z-index: 11;
  cursor: pointer;
  transform-origin: center;
  position: fixed;
  right: 20px;
  bottom: 20px;
  transition: 1s;
}
.contactField .maGanache {
  width: 100px;
  border-radius: 100px;
  transform: scaleX(-1);
}
@media (min-width: 650px) {
  .contactField .headerLogos:hover .bdMini {
    transition: 0.25s;
    width: 80px;
    height: 30px;
    bottom: 4px;
    opacity: 1;
  }
}
.contactField .bdMaxi {
  transition: 0.25s;
  width: 35vw;
  right: 0;
  height: 60vh;
  bottom: 4px;
  opacity: 1;
  border-radius: 30px;
  border-bottom-right-radius: 0px 80px;
  background-color: white;
  border: solid 2px black;
  position: absolute;
  transition: 0.25s;
}
.contactField .noDiscuss {
  margin: auto;
}
.contactField .discuss {
  width: 93%;
  height: 100%;
  padding: 10px;
  height: calc(60vh - 100px);
  overflow: scroll;
  margin-top: 10px;
  cursor: default;
  flex-direction: column;
}
.contactField .discuss .hidden {
  opacity: 0;
  transition: 1s;
}
.contactField .discuss .gradient {
  position: absolute;
  width: 93%;
  height: 150px;
  top: 2px;
  border-radius: 10px;
  background: linear-gradient(#ffffff, rgba(255, 255, 255, 0));
  transition: 1s;
}
.contactField .discuss .txt {
  margin-left: 5px;
  margin-top: -10px;
}
@media (max-width: 650px) {
  .contactField .discuss .txt {
    margin-top: 0;
  }
}
.contactField .discuss .oneMessage {
  display: flex;
  flex-direction: row;
}
.contactField .discuss .avatar {
  width: 30px;
  height: 30px;
}
@keyframes clignote {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.contactField .discuss .sendButton {
  width: calc(18% - 30px);
  min-width: 30px;
  height: 6vh;
  display: flex;
  cursor: pointer;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  animation: infinite clignote;
  animation-duration: 2000ms;
  opacity: 1;
}
.contactField .discuss .sendButton img {
  width: 100%;
  margin: auto;
}
@media (max-width: 650px) {
  .contactField .discuss .sendButton {
    height: 8vh;
  }
}
.contactField .discuss textarea {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: calc(85% - 30px);
  height: 6vh;
  resize: none;
  border-bottom-left-radius: 20px 20px;
  transition: 0.1s;
}
@media (max-width: 650px) {
  .contactField .discuss textarea {
    height: 8vh;
  }
}
.contactField .discuss textarea:focus {
  outline: 2px solid rgba(153, 71, 122, 0.7176470588);
  transition: 0.1s;
}
@media (max-width: 650px) {
  .contactField .bdMaxi {
    width: 90vw;
    height: calc(100dvh - 190px);
  }
}/*# sourceMappingURL=contact.css.map */