.cardField{
    width: 20%;
    min-width: 250px;
    max-width: 350px;
    height: 350px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    margin: 20px;
    z-index: 5;
    .logoCards{
        position: absolute;
        z-index: 6;
        top: 10px;
        left: 10px;
        display: flex;
        flex-direction: column;
        .topProj{
            height: 33px;
            width: 35px;
        }
        .studies{
            width: 35px;
        }
    }
    p{
        font-size: 1rem;
    }
    .more{
        position: absolute;
        bottom: -10px;
        right: 10px;
        font-size: 10px;
        opacity: 0.5;
    }
    @media (max-width: 650px) {
        //width: 80%;
        max-width: 300px;
    }
    @media (max-width: 900px) {
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    border: solid white;
    text-align: center;
    .likeSlide{
        position: relative;
        top: 114px;
    }
    .cardSlide{
        opacity: 1;
        height: 162px;
    }
    .cardResum{
        position: absolute;
        top: 150px;
        color: white;
        background-color: #99477a ;
        opacity: 0.8;
        transition: all ease-in-out 4s; 
        z-index: 1;
        height: calc( 100% - 150px);
        min-width: 230px;
        width: 100% ;
        display: flex;
        .texte{
            padding: 10px;
            margin: auto;
        }
        .likeCard{
            position: relative;
            top: 150px;
        }
    }
}
.cardField:hover {
    .cardResum{
        opacity: 1;
        transition: all ease-in-out 4s; 
    }
    .likeContainer{
        right: -20px;
        transition: 1000ms;
    }
    .more{
        opacity: 1;
    }
}

