
  .cofeeContainer {
    position: absolute;
    bottom: -10px;
    left: 20px;
    display: flex;
    flex-direction: column-reverse;
    transition: 1s;
    .cofee{
        width: 50px;
        height: 50px;
        filter: invert(1);
      }
      .smoke{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: 3px;
      }
      .cofeeSmoke{
        width: 4px;
        height: 15px;
        border-radius: 4px;
        background-color: white;
        margin-left: 3px;
        margin-right: 3px;
        margin-bottom: -8px;
        animation-name: smoking;
        animation-duration: 6000ms;
        animation-iteration-count: infinite;
      }
      .secondSmoke{
        animation-delay: 500ms;
      }
    
      @keyframes smoking{
        0% {
            opacity: 1;
        }
        40% {
            opacity: 0;
            transform:  translate(0,-10px) ;
        }
        100% {
          opacity: 0 ;
          transform:  translate(0,0px)
        }  
      }
  }
