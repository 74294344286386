.likeContainer {
  transition: 1000ms;
  position: absolute;
  right: -350px;
  opacity: 0.8;
  z-index: 6;
}
.likeContainer .like {
  display: flex;
  flex-direction: row;
  min-width: 100px;
  border-radius: 20px;
  padding-left: 20px;
  height: 36px;
  justify-content: flex-end;
  text-align: right;
}
.likeContainer .like p {
  margin: auto;
  padding-top: 5px;
  margin-right: 55px;
}
@media (max-width: 900px) {
  .likeContainer {
    right: -20px;
  }
}
.likeContainer .poc {
  position: absolute;
  display: none;
  margin-top: -5px;
}
.likeContainer .pocContain {
  position: relative;
}
.likeContainer .poc1 {
  left: -3px;
  top: -4px;
}
.likeContainer .poc2 {
  left: 0px;
  top: -9px;
}
.likeContainer .poc3 {
  left: 7px;
  top: -11px;
}
.likeContainer .poc4 {
  left: 14px;
  top: -9px;
}
.likeContainer .poc5 {
  left: 17px;
  top: -4px;
}
.likeContainer .checked {
  animation-name: like;
  animation-duration: 2000ms;
}
.likeContainer .checked .poc {
  display: block;
  animation-name: poc;
  animation-duration: 2000ms;
}
.likeContainer .buttonLike {
  background: none;
  border: none;
  width: 20px;
  position: absolute;
  right: 30px;
  top: 6px;
}
.likeContainer .buttonLike .logoLike {
  margin-left: -5px;
  width: 20px;
  margin-bottom: -6px;
  cursor: pointer;
}

@keyframes like {
  0% {
    transform: translateX(-1px);
  }
  6% {
    transform: translateX(1px);
  }
  12% {
    transform: translateX(-1px);
  }
  18% {
    transform: translateX(1px);
  }
  24% {
    transform: translateX(-1px);
  }
  32% {
    transform: translateX(1px);
  }
  40% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes poc {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  37% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
.likeColorwithe {
  background-color: white;
  color: black;
}
.likeColorwithe .buttonLike {
  color: black;
}
.likeColorwithe p {
  color: black;
}
.likeColorwithe img {
  filter: invert(0);
}

.likeColorblack {
  background-color: black;
  color: white;
}
.likeColorblack .buttonLike {
  color: inherit;
}
.likeColorblack img {
  filter: invert(1);
}/*# sourceMappingURL=like.css.map */