.ReactContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  perspective: 50000px;
  color: black;
}
.ReactContainer span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  background-color: black;
  width: 18.4%;
  height: 18.4%;
  border-radius: 50%;
}
.ReactContainer div {
  position: absolute;
  width: 100%;
  height: 100%;
  border-left: 13.32px solid;
  border-right: 5.123076px solid;
  border-top: 13.32 solid transparent;
  border-radius: 100%;
}
.ReactContainer div:nth-child(1) {
  animation: anim-1 1s linear infinite;
}
.ReactContainer div:nth-child(2) {
  animation: anim-2 1s linear infinite;
}
.ReactContainer div:nth-child(3) {
  animation: anim-3 1s linear infinite;
}
@keyframes anim-1 {
  from {
    transform: rotatez(120deg) rotateX(66deg) rotateZ(0);
  }
  to {
    transform: rotatez(120deg) rotateX(66deg) rotateZ(360deg);
  }
}
@keyframes anim-2 {
  from {
    transform: rotatez(240deg) rotateX(66deg) rotateZ(0);
  }
  to {
    transform: rotatez(240deg) rotateX(66deg) rotateZ(360deg);
  }
}
@keyframes anim-3 {
  from {
    transform: rotatez(360deg) rotateX(66deg) rotateZ(0);
  }
  to {
    transform: rotatez(360deg) rotateX(66deg) rotateZ(360deg);
  }
}/*# sourceMappingURL=react.css.map */